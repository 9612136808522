body{
    padding: 0px;
    margin: 0px;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    width: 9px;    
    height: 8px; 
    display: block; 
}

body::-webkit-scrollbar-thumb {
    background: rgb(151, 151, 151);
    border-radius: 4px;
}


body::-webkit-scrollbar-thumb:hover {
    background: #b3b3b3;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

body::-webkit-scrollbar-track {
    background: #e1e1e1;
    border-radius: 4px;
}

body::-webkit-scrollbar-track:hover,
body::-webkit-scrollbar-track:active {
  background: #d4d4d4;
}

:root {
    --main-font: 'Quicksand';
    --main-color: #2F80ED;
}

h1{
    font-family: 'Quicksand';
}

.container{
    width: 40% !important;
    min-height: calc(95vh - 90px);
}

ul{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin: 0px auto;
}

a{
    font-size: 28px;
    text-decoration: none;
    color: black;
    font-family: var(--main-font);
    transition: all 300ms;
}

a:hover {
    color: black;
    transform: scale(1.2, 1.2);
}

.active{
    transform: scale(1.2, 1.2) !important;
    border-bottom: 6px solid var(--main-color);
}

.todo-div{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
}

.todo-div p{
    width: 80%;
    word-break: break-all;
    font-size: 20px;
    font-family: var(--main-font);
}

.todo-div input {
    width: 30px !important;
    height: 20px;
}

.completed{
    text-decoration: line-through;
}

svg:hover{
    cursor: pointer;
}

.no-tasks{
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

.no-tasks svg{
    cursor: auto;
}

.no-tasks h3{
    font-family: var(--main-font);
    font-size: 33px;
}

footer{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 40px;
    margin-top: 50px;
}

footer h3{
    font-family: var(--main-font);
}

@media(max-width: 1243px){
    .container{
        width: 90% !important;
    }
}

@media(max-width: 800px){
    .container{
        width: 100% !important;
    }
}

@media(max-width: 576px){
    a{
        font-size: 22px;
    }
    .active{
        transform: scale(1.1, 1.1) !important;
    }
}

@media(max-width: 550px){
    .todo-div p{
        width: 60%;
        word-break: break-word;
    }
    
    .todo-div input {
        width: 18% !important;
        height: 20px;
    }
}

@media(max-width: 483px){
    .btn-danger{
        width: 150px;
    }
}

@media(max-width: 416px){
    .no-tasks svg{
        width: 280px;
    }

    .todo-all-no-tasks-svg{
        width: 200px !important;
    }

    .no-tasks h3{
        font-size: 27px;
    }
}